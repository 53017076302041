import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import About from "./components/About/About";
import Blog from "./components/Blog/Blog";
import Careers from "./components/Careers/Careers";
import Dealers from "./components/dealers/Dealers";
import Enterprise from "./components/Enterprise/Enterpirse";
import Events from "./components/Events/Events";
import Faq from "./components/faq/Faq";
import Footer from './components/Footer/Footer';
import Marketing from "./components/Marketing/Marketing";
import News from "./components/News/News";
import Resources from "./components/Resources/Resources";
import Signin from "./components/Signin/Signin";
import Signup from "./components/Signup/Signup";
import Spend from "./components/SpendCalculator/SpendCalculator";
import Team from "./components/Team/Team";
import Home from "./components/Home/Home";
const App = () => {
  return (
    <>
    <Router>
      <Routes>
      <Route path="/" element={<Home></Home>}></Route>
        <Route path="/about" element={<About></About>}></Route>
        <Route path="/blog" element={<Blog></Blog>}></Route>
        <Route path="/careers" element={<Careers></Careers>}></Route>
        <Route path="/dealers" element={<Dealers></Dealers>}></Route>
        <Route path="/enterprise" element={<Enterprise></Enterprise>}></Route>
        <Route path="/events" element={<Events></Events>}></Route>
        <Route path="/faq" element={<Faq></Faq>}></Route>
        <Route path="/footer" element={<Footer></Footer>}></Route>
        <Route path="/marketing" element={<Marketing></Marketing>}></Route>
        <Route path="/news" element={<News></News>}></Route>
        <Route path="/resources" element={<Resources></Resources>}></Route>
        <Route path="/signin" element={<Signin></Signin>}></Route>
        <Route path="/signup" element={<Signup></Signup>}></Route>
        <Route path="/spend" element={<Spend></Spend>}></Route>
        <Route path="/team" element={<Team></Team>}></Route>
      </Routes>
    </Router>
    
    </>
  )
}

export default App
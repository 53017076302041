import React from 'react';
import Logo from '../../atis-assets/logo/atis/Engageall.svg';
import Fb from "../../atis-assets/social/facebook.svg";
import Insta from "../../atis-assets/social/instagram.svg";
import Twitter from "../../atis-assets/social/twitter.svg";
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  return (
    <>

<section className="skewed-bottom-right">
        <nav 
          className="relative px-4 py-2 shadow-xl flex justify-between items-center  bg-blue-50"
        >
          <div className="text-3xl font-bold leading-none" onClick={()=>navigate("/")}>
            <img
              style={{width:150,height:100}}
              src={Logo}
              alt=""
              width="auto"
            />
          </div>
          <div className="lg:hidden">
            <button className="navbar-burger flex items-center text-gray-400 p-3">
              <svg
                className="block h-4 w-4 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Mobile menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </button>
          </div>
          <ul
            className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto  lg:items-center lg:w-auto lg:space-x-6"
          >
            <li className="-ml-40 px-4">
               
              <div
                className="text-sm font-bold text-blue-900 "
                onClick={()=>navigate("/signup")}
                >START</div>
            </li>
            <li id="dropdown" className="relative" x-data="{isOpen:false}">
              <button   className=" block text-sm font-bold outline-none  focus:outline-none  text-blue-900 " href="#">
               COMPANY </button>
                
              
              <div id="dropdown-menu"
                className="right-0 p-2 mt-0 bg-white rounded-md shadow lg:absolute">
                <ul  className="space-y-2 lg:w-48">
                  <li>
                <div onClick={()=>navigate("/team")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Our Team</div></li>
                <li><div onClick={()=>navigate("/careers")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black"> Careers</div></li>

          <li><div onClick={()=>navigate("/careers")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">News</div></li>
          
          
                 
                </ul>
                 </div>
                
            </li>
            <li>
                <svg id="svg" width="12px" height="12px"  viewBox="0 0 64 64" 
                xmlns="http://www.w3.org/2000/svg" 
                stroke-width="8" stroke="#1e3a8a" fill="none">
                  <polyline points="6.53 18.86 33.16 44.12 57.42 18.86"/>
                </svg>
            </li>
           
            <li id="dropdown" className="relative" x-data="{isOpen:false}">
              <button   className=" block text-sm font-bold outline-none  focus:outline-none  text-blue-900 " href="#">
                RESOURCES </button>
                
              
              <div id="dropdown-menu"
                className="right-0 p-2 mt-0 bg-white rounded-md shadow lg:absolute">
                <ul  className="space-y-2 lg:w-48">
                  <li>
                <div onClick={()=>navigate("/resources")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Resources</div></li>
                <li><div onClick={()=>navigate("/marketing")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Marketing kit</div></li>

          <li><div onClick={()=>navigate("/events")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Events</div></li>
          
          <li><div onClick={()=>navigate("/blog")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Blog</div></li>
          <li><div onClick={()=>navigate("/spend")}className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Spend Calculator</div></li>
                 
                </ul>
                 </div>
                
            </li>
           
            <li>
                <svg id="svg" width="12px" height="12px"  viewBox="0 0 64 64" 
                xmlns="http://www.w3.org/2000/svg" 
                stroke-width="8" stroke="#1e3a8a" fill="none">
                  <polyline points="6.53 18.86 33.16 44.12 57.42 18.86"/>
                </svg>
            </li>
              <li id="dropdown" className="relative" x-data="{isOpen:false}">
                <button   className=" block text-sm font-bold outline-none  focus:outline-none  text-blue-900 " href="#">
                  PRODUCTS </button>
                  
                
                <div id="dropdown-menu"
                  className="right-0 p-2 mt-0 bg-white rounded-md shadow lg:absolute">
                  <ul  className="space-y-2 lg:w-48">
                    <li>
                  <div onClick={()=>navigate("/dealers")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Dealers</div></li>
                  <li><div onClick={()=>navigate("/enterprise")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black"> Enterprise</div></li>
  
            
                   
                  </ul>
                   </div>
                  
              </li>
            
           
            
           
           
            <li>
                <svg id="svg" width="12px" height="12px"  viewBox="0 0 64 64" 
                xmlns="http://www.w3.org/2000/svg" 
                stroke-width="8" stroke="#1e3a8a" fill="none">
                  <polyline points="6.53 18.86 33.16 44.12 57.42 18.86"/>
                </svg>
            </li>
          
            <li>
              <div className="text-sm font-bold text-blue-900" href="#">TESTIMONIALS</div>
            </li>
            
            
            
            
          </ul>
          <div className="flex justify-between">
            
            <a
          className="hidden lg:inline-block py-2 px-6   text-sm text-blue-900 font-bold "
          href="https://mortgagesaas.netlify.app/authentication/login"
          >LOGIN</a
        >
        
    
    
      <a
        className="hidden lg:inline-block py-2 px-6 bg-blue-900 hover:bg-blue-900 text-sm text-white font-bold rounded-l-xl rounded-t-xl transition duration-200 "
        href="#demoform"
        >BOOK A DEMO</a
      >
        
  
      </div>
        </nav>
        <div className=" pt-12 lg:pt-20 pb-20 bg-white radius-for-skewed">
          <div className="container  mx-auto px-4">
            <div className="flex flex-wrap -mx-4">
              <div
                className="w-full  lg:w-1/2 px-4 mb-12 md:mb-20 lg:mb-0 flex items-center"
              >
                <div className="w-full text-center lg:text-left ">
                  <div className="max-w-md mx-auto lg:mx-0">
                    <h2
                      className="mb-3 text-4xl lg:text-5xl font-bold font-heading"
                    >
                      <span>Pipeline &amp; Audience </span>
                      <span className="text-blue-900">Engagement</span>
                    </h2>
                  </div>
                  <div className="max-w-sm mx-auto lg:mx-0">
                    <p className="mb-6 text-gray-400 leading-loose">
                      Engage every party and exceed expectations and goals by delivering a consistent experience. Build, partnerships and retain customers. 

                    </p>
                    <div>
                      <a
                        className="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
                        href="signup.html"
                        >Get Started</a
                      ><a className="inline-block w-full lg:w-auto py-2 px-6 leading-loose font-semibold bg-white hover:bg-gray-50 rounded-l-xl rounded-t-xl transition duration-200"
                        href="/">How it works</a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="w-full lg:w-1/2 px-4 flex items-center justify-center"
              >
                
                  <div id="bcircle">
                    <div id="scircle">

                    </div>
                  </div> 
                  <div id="bcircletwo" >
                    <div id="scircletwo">

                    </div>
                  </div>
                  <div id="bcirclethree" >
                    <div id="scirclethree">

                    </div>
                  </div>
                  <div className="relative " style={{zIndex:10}}>
                     
                      <img
                      className="h-96 w-128 max-w-lg  object-cover rounded-3xl md:rounded-br-none"
                      src="https://res.cloudinary.com/duuwbcn8o/image/upload/v1670914165/oscar-nord-8l9VxXI28tY-unsplash__1_-removebg-preview_pz7fst.png"
                      alt=""
                    />
                   
                  </div>
                 
                 
               
              </div>
            </div>
          </div>
        </div>
        <div className="mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveaspectratio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
          </svg>
        </div>
        <div
          className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50"
        >
          <div
            className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"
          ></div>
          <div
            className="relative flex flex-col py-6 px-6 h-full w-full bg-white border-r overflow-y-auto"
          >
            <div className="flex items-center mb-8">
              <a className="mr-auto text-3xl font-bold leading-none" href="/">
                <img
                 style={{width:150,height:100}}
                  src={Logo}
                  alt=""
                  width="auto"
                />
              </a>
              <button className="navbar-close">
                <svg
                  className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <ul>
                <li className="mb-1">
                  <a
                    className="block p-4 text-sm font-semibold text-blue-900 hover:bg-green-50 hover:text-blue-900 rounded"
                    href="signup.html"
                    >START</a
                  >
                </li>
                <li id="dropdown" className="mb-1">
                  <a
                    className="block p-4 text-sm font-semibold text-blue-900 hover:bg-green-50 hover:text-blue-900 rounded"
                    href="/"
                    >COMPANY <span className="material-icons md-24 blue900 ">
                      keyboard_arrow_down
                      </span></a >
                  <div id="dropdown-menu"
                      className="right-0 p-2 mt-0 bg-white rounded-md shadow lg:absolute">
                      <ul  className="space-y-2 lg:w-48">
                        <li>
                          <a href="team.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Our Team</a></li>
                          <li><a href="careers.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Careers</a></li>
          
                    <li><a href="news.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">News</a></li>
                    
                    
                       
                      </ul>
                       </div>
                </li>
                <li id="dropdown" className="mb-1" x-data="{isOpen:false}">
                  
                  <a
                  className="block p-4 text-sm font-semibold text-blue-900 hover:bg-green-50 hover:text-blue-900 rounded"
                  href="/"
                  >RESOURCES <span className="material-icons md-24 blue900 ">
                    keyboard_arrow_down
                    </span></a
                >
                    
                    <div id="dropdown-menu"
                      className="right-0 p-2 mt-0 bg-white rounded-md shadow lg:absolute">
                      <ul  className="space-y-2 lg:w-48">
                        <li>
                          <a href="resources.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Resources</a></li>
                          <li><a href="marketing.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Marketing kit</a></li>
          
                    <li><a href="events.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Events</a></li>
                    
                    <li><a href="blog.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Blog</a></li>
                    <li><a href="spendcalculator.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Spend Calculator</a></li>
                       
                      </ul>
                       </div>
                      
                  </li>
                 
                 
                </ul>
                
                <li id="dropdown" className="mb-1">
                  <a
                    className="block p-4 text-sm font-semibold text-blue-900 hover:bg-green-50 hover:text-blue-900 rounded"
                    href="/"
                    >PRODUCTS
                    <span className="material-icons md-24 blue900 ">
                      keyboard_arrow_down
                      </span></a
                  > 
                  <div id="dropdown-menu"
                      className="right-0 p-2 mt-0 bg-white rounded-md shadow lg:absolute">
                      <ul  className="space-y-2 lg:w-48">
                        <li>
                          <a href="dealers.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Dealers</a></li>
                          <li><a href="enterprise.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Enterprise</a></li>
          
                    
                       
                      </ul>
                       </div>
                </li>
                <li className="mb-1">
                  <a
                    className="block p-4 text-sm font-semibold text-blue-900 hover:bg-green-50 hover:text-blue-900 rounded"
                    href="/"
                    >TESTIMONIALS</a
                  >
                </li>
                <li className="mb-1">
                  <a
                    className="block p-4 text-sm font-semibold text-blue-900 hover:bg-green-50 hover:text-blue-900 rounded"
                    href="#demoform"
                    >BOOK A DEMO</a
                  >
                </li>
            </div>
            <div className="mt-auto">
              <div className="pt-6">
                <a
                  className="block px-4 py-3 mb-3  text-xs text-center font-semibold leading-none bg-gray-50 text-blue-900 hover:bg-gray-100 rounded-l-xl rounded-t-xl"
                  href="/signin"
                  >Sign In</a>
                  <a className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-blue-900 hover:bg-blue-900 rounded-l-xl rounded-t-xl"
                  href="/signup">Sign Up</a>
              </div>
              <p className="my-4 text-xs text-center text-gray-400">
                <span>© 2020 All rights reserved.</span>
              </p>
              <div className="text-center">
                <a className="inline-block px-1" href="/">
                  <img src={Fb} alt="" />
                </a>
                <a className="inline-block px-1" href="/">
                  <img src={Twitter} alt="" />
                </a>
                <a className="inline-block px-1" href="/">
                  <img src={Insta} alt="" />
                </a>
              </div>
              </div>
         </div>
         </div>
   </section>

    <section>
        <div className="skew skew-top mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
          </svg>
        </div>
        <div className="skew skew-top ml-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
          </svg>
        </div>
        <div className="py-20 bg-gray-50 radius-for-skewed">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap items-center">
              <div className="w-full lg:w-1/2 mb-12 lg:mb-0">
                <div className="max-w-md lg:mx-auto">
                  
                  <h2 className="my-2 text-4xl lg:text-5xl font-bold font-heading">
                    Customer success fueled by engagement
                  </h2>
                  <p className="mb-6 text-gray-500 leading-loose">
                    Provide milestone updates every step of the way keeping your loans on track and everyone happy. 
                  </p>
                  
                </div>
              </div>
              <div className="w-full lg:w-1/2 flex flex-wrap -mx-4">
                <div className="mb-8 lg:mb-0 w-full md:w-1/2 px-4">
                  <div className="mb-8 py-6 pl-6 pr-4 shadow-xl rounded bg-white">
                    <span className="mb-4 inline-block p-3 rounded-lg bg-green-100">
                      <svg
                        className="w-10 h-10 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        ></path>
                      </svg>
                    </span>
                    <h4 className="mb-2 text-2xl font-bold font-heading">
                      Visibility

                    </h4>
                    <p className="text-gray-500 leading-loose">
                      Get complete visibility into every loan. 
                    </p>
                  </div>
                  <div className="py-6 pl-6 pr-4 shadow-xl rounded bg-white">
                    <span className="mb-4 inline-block p-3 rounded-lg bg-green-100">
                      <svg
                        className="w-10 h-10 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
                        ></path>
                      </svg>
                    </span>
                    <h4 className="mb-2 text-2xl font-bold font-heading">
                      Consistency
                    </h4>
                    <p className="text-gray-500 leading-loose">
                      Drive consistency on every purchase transaction. Get better and better as you close more and more.

                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 lg:mt-20 px-4">
                  <div className="mb-8 py-6 pl-6 pr-4 shadow-xl rounded-lg bg-white">
                    <span className="mb-4 inline-block p-3 rounded bg-green-100">
                      <svg
                        className="w-10 h-10 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        ></path>
                      </svg>
                    </span>
                    <h4 className="mb-2 text-2xl font-bold font-heading">
                      Updating
                    </h4>
                    <p className="text-gray-500 leading-loose">
                      Update the client, the Buyers Agent and the Sellers Agent while shining a light on your professionalism and attention to detail.

                    </p>
                  </div>
                  <div className="py-6 pl-6 pr-4 shadow-xl rounded-lg bg-white">
                    <span className="mb-4 inline-block p-3 rounded bg-green-100">
                      <svg
                        className="w-10 h-10 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        ></path>
                        <path
                          d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"
                        ></path>
                      </svg>
                    </span>
                    <h4 className="mb-2 text-2xl font-bold font-heading">
                      Retention
                    </h4>
                    <p className="text-gray-500 leading-loose">
                      Agent Retention and Engagement all in one place. 

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="skew skew-bottom mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
          </svg>
        </div>
        <div className="skew skew-bottom ml-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
          </svg>
        </div>
      </section>

      <section>
        <div className="skew skew-top mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
          </svg>
        </div>
        <div className="skew skew-top ml-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
          </svg>
        </div>


        <div className="py-20 bg-white radius-for-skewed">

 <div className="container mx-auto px-4">
            <div className="max-w-2xl mx-auto text-center mb-16">
              <span className="text-blue-900 font-bold"
                >We make it easy
                </span
              >
              <h2 className="mb-2 text-4xl lg:text-5xl font-bold font-heading">
                One Plan

              </h2>
              <p className="mb-6 text-gray-500">
                Four Options
              </p>
              <div className="inline-block py-1 px-1 bg-white rounded-lg">
                <button
                  className="mr-1 text-sm py-2 px-4 text-gray-500 hover:text-gray-900 font-bold"
                >
                  Monthly
                </button>
                
              </div>
            </div>
            <div className="flex justify-center -mx-4">
              <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
                <div className="p-8 bg-white shadow-xl rounded">
                  <h4 className="mb-2 text-2xl font-bold font-heading">Monthly</h4>
                  <span className="text-6xl font-bold">$59.95 </span>
                  <p className="mt-3 mb-6 text-gray-500 leading-loose">
                    user/month

                  </p>
                  <ul className="mb-6 text-gray-500">
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span> You can pay once a month(monthly).</span>
                    </li>
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>You can pay in every three months(quarterly). </span>
                    </li>
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span> You can pay in every six months (semi-annually) or once a year(annually).</span>
                    </li>
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>if you choose to pay once a year, you will save one month’s payment of $59.95. Instead of paying $719.40, you would pay $659.45.
                      </span>
                    </li>
                    
                  </ul>
                  <a
                    className="inline-block text-center py-2 px-4 w-full rounded-l-xl rounded-t-xl bg-blue-900 hover:bg-blue-700 text-white font-bold leading-loose transition duration-200"
                    href="/"
                    >Get Started</a>
                </div>
              </div>
              {/* <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
                <div className="p-8 bg-blue-900 shadow rounded">
                  <h4 className="mb-2 text-2xl font-bold text-white">
                    Intermediate
                  </h4>
                  <span className="text-6xl font-bold text-white">$24</span>
                  <span className="text-gray-50 text-xs">/month</span>
                  <p className="mt-3 mb-6 leading-loose text-gray-50">
                    Nullam diam arcu, sodales quis convallis sit amet, sagittis
                    varius ligula.
                  </p>
                  <ul className="mb-6 text-gray-50">
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-green-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Vestibulum viverra</span>
                    </li>
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-green-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Morbi mollis metus pretium</span>
                    </li>
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-green-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Etiam lectus nunc, commodo</span>
                    </li>
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-green-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Ut quam nisl mollis id pretium</span>
                    </li>
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-green-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Suspendisse bibendum</span>
                    </li>
                  </ul>
                  <a
                    className="inline-block text-center py-2 px-4 w-full rounded-l-xl rounded-t-xl bg-white hover:bg-gray-50 font-bold leading-loose transition duration-200"
                    href="#"
                    >Get Started</a
                  >
                </div>
              </div>  */}
              {/* <div className="w-full lg:w-1/3 px-4">
                <div className="p-8 bg-white shadow rounded">
                  <h4 className="mb-2 text-2xl font-bold font-heading">
                    Professional
                  </h4>
                  <span className="text-6xl font-bold">$48</span>
                  <span className="text-gray-400 text-xs">/month</span>
                  <p className="mt-3 mb-6 text-gray-500 leading-loose">
                    Nullam diam arcu, sodales quis convallis sit amet, sagittis
                    varius ligula.
                  </p>
                  <ul className="mb-6 text-gray-500">
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Vestibulum viverra</span>
                    </li>
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Morbi mollis metus pretium</span>
                    </li>
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Etiam lectus nunc, commodo</span>
                    </li>
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Ut quam nisl mollis id pretium</span>
                    </li>
                    <li className="mb-2 flex">
                      <svg
                        className="mr-2 w-5 h-5 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span>Suspendisse bibendum</span>
                    </li>
                  </ul>
                  <a
                    className="inline-block text-center py-2 px-4 w-full rounded-l-xl rounded-t-xl bg-blue-900 hover:bg-blue-900 text-white font-bold leading-loose transition duration-200"
                    href="#"
                    >Get Started</a
                  >
                </div>
              </div>  */}
            </div>
          </div>
        </div>



        <div className="py-20 bg-gray-50 radius-for-skewed">

          <div className="container mx-auto px-4">
                     <div className="max-w-2xl mx-auto text-center mb-16">
                       
                       <h2 className="mb-2 text-4xl lg:text-5xl font-bold font-heading">
                        Product Features
                       </h2>
                       
                       
                     </div>
                     <div className="flex flex-wrap -mx-4">
                       <div className="w-max md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
                         <div className="p-8 bg-white m-2 shadow-xl rounded">
                           <h4 className="mb-2 text-2xl font-bold font-heading">Automation</h4>
                           
                           
                          
                         </div>
                       </div>
                       <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
                         <div className="p-8 bg-blue-900 m-2 shadow-xl rounded">
                           <h4 className="mb-2 text-2xl font-bold text-white">
                        Segmentation
                           </h4>
                           
                           
                           
                         </div>
                       </div>
                       <div className="w-full lg:w-1/3 px-4">
                         <div className="p-8 bg-white m-2 shadow-xl rounded">
                           <h4 className="mb-2 text-2xl font-bold font-heading">
                           Personalization
                           </h4>
                          
                          
                         </div>
                       </div>
                       <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
                        <div className="p-8 bg-white m-2 shadow-xl rounded">
                          <h4 className="mb-2 text-2xl font-bold font-heading">Analytics</h4>
                          
                          
                         
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
                        <div className="p-8 bg-white m-2 shadow-xl rounded">
                          <h4 className="mb-2 text-2xl font-bold font-heading">Stage Tracking</h4>
                          
                          
                         
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
                        <div className="p-8 bg-white m-2 shadow-xl rounded">
                          <h4 className="mb-2 text-2xl font-bold font-heading">User-friendly Interface</h4>
                          
                          
                         
                        </div>
                      </div>
                      
                      
                    
                     
                     </div>
                   </div>
                 </div>





        <div className="skew skew-bottom mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
          </svg>
        </div>
        <div className="skew skew-bottom ml-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
          </svg>
        </div>
      </section>

      <section>
        <div className="skew skew-top mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
          </svg>
        </div>
        <div className="skew skew-top ml-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
          </svg>
        </div>
         {/* <div className="py-20 bg-gray-50 radius-for-skewed">
          <div className="container mx-auto px-4">
            <div className="mb-12">
              <h2 className="text-4xl lg:text-5xl font-bold font-heading">
                Contact
              </h2>
              <p className="text-gray-500 leading-loose">
                Got any question? Let’s talk about it.
              </p>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/2 mb-16 lg:mb-0">
                <div className="flex flex-wrap">
                   <div className="mb-12 w-full md:w-1/2 lg:w-1/2">
                    <h3 className="mb-2 text-3xl lg:text-4xl font-bold">Office</h3>
                    <p className="text-gray-400">359 Hidden Valley</p>
                    <p className="text-gray-400">Road, NY</p>
                  </div>  */}
                  <div className="mb-12 w-full md:w-1/2">
                     {/* <h3 className="mb-2 text-3xl lg:text-4xl font-bold">
                      Contacts
                    </h3>
                    <p className="text-gray-400">hallo@gamil.com</p>  */}
                  </div>
                    {/* <div className="w-full md:w-1/3 lg:w-full"> 
                     <h3 className="mb-2 text-3xl lg:text-4xl font-bold">Socials</h3> 
                     <div className="flex">  */}
                      {/* <!-- <a className="mr-3" href="#">
                        <img src="atis-assets/social/facebook.svg" alt="" />
                      </a>
                      <a className="mr-3" href="#">
                        <img src="atis-assets/social/twitter.svg" alt="" />
                      </a>
                      <a href="#">
                        <img src="atis-assets/social/instagram.svg" alt="" />
                      </a>
                    </div>
                  </div> 
                </div>
              </div>
               <div className="w-full lg:w-1/2">
                <div className="max-w-md lg:mx-auto">
                  <form action="">
                    <div className="mb-4 text-sm">
                      <span className="mr-4 font-semibold">Department:</span>
                      <label className="mr-4">
                        <input
                          className="mr-1"
                          type="radio"
                          name="department"
                          value="1"
                          checked
                        />
                        <span>Support</span>
                      </label>
                      <label>
                        <input
                          className="mr-1"
                          type="radio"
                          name="department"
                          value="2"
                        />
                        <span>Sales</span>
                      </label>
                    </div>
                    <div className="mb-4">
                      <input
                        className="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none"
                        type="text"
                        placeholder="Subject"
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        className="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none"
                        type="text"
                        placeholder="Name"
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        className="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none"
                        type="email"
                        placeholder="name@example.com"
                      />
                    </div>
                    <div className="mb-4">
                      <textarea
                        className="w-full h-24 p-4 text-xs font-semibold leading-none resize-none bg-white rounded outline-none"
                        type="text"
                        placeholder="Message..."
                      ></textarea>
                    </div>
                    <div className="mb-4">
                      <label className="flex px-2 bg-white rounded">
                        <input
                          className="hidden"
                          type="file"
                          placeholder="Choose file.."
                          name="Choose file"
                        />
                        <div
                          className="my-1 ml-auto px-4 py-3 text-xs text-white font-semibold leading-none bg-gray-500 hover:bg-gray-600 rounded cursor-pointer transition duration-200"
                        >
                          Browse
                        </div>
                      </label>
                    </div>
                    <div className="flex justify-between items-center">
                      <label>
                        <input
                          className="mr-1"
                          type="checkbox"
                          name="terms"
                          value="1"
                        />
                        <span className="text-sm font-semibold"
                          >I agree to terms and conditions.</span
                        >
                      </label>
                      <button
                        className="inline-block py-2 px-6 rounded-l-xl rounded-t-xl bg-blue-900 hover:bg-blue-700 text-gray-50 font-bold leading-loose transition duration-200"
                      >
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>  */}
            {/* </div>
          </div>
        </div>   */}

        <div className="container mx-auto px-4 my-auto">
    <div id="demoform" className="max-w-2xl mx-auto text-center mb-16">

        <h2 className="mb-2 text-4xl lg:text-5xl font-bold font-heading">BOOK A DEMO</h2>
        
    </div>

     {/* <div className="py-20 bg-gray-50 radius-for-skewed">  */}
      <div className="  w-full shadow-xl lg:w-full md:w-1/2 sm:w-1/2">
        <div className="  max-w-md lg:mx-auto ">
          
          <form action="#demoform" method="POST" className="my-6">
            
            <div className="inline-block m-4  ">
              <input
                className="lg:w-full rounded-3xl border-2 text-black p-4 text-xs font-semibold leading-none bg-white  outline-none"
                type="text"
                name="firstname"
                placeholder="First Name"
              />
            </div>
            <div className=" inline-block m-4  ">
              <input
                className="lg:w-full p-4 rounded-3xl border-2  text-xs font-semibold leading-none bg-white  outline-none"
                type="text"
                name="lastname"
                placeholder="Last Name"
              />
            </div>
            <div className="inline-block m-4 ">
              <input
                className="lg:w-full p-4  border-2  only:text-xs font-semibold leading-none bg-white rounded-3xl outline-none"
                type="email"
                name="email"
                placeholder="name@example.com"
              />
            </div>
            <div className="inline-block m-4 ">
              <input
                className="w-full  rounded-3xl border-2  p-4 text-xs font-semibold leading-none bg-white  outline-none"
                type="text"
                name="number"
                placeholder="Phone Number"
              />
            </div>
            <div className="inline-block m-4 ">
              <input
                className="w-full p-4   text-xs font-semibold leading-none bg-white rounded-3xl border-2 outline-none"
                type="text"
                name="companyname"
                placeholder="Company Name"
              />
            </div>
            <div className="inline-block m-4 ">
              <input
                className="w-full p-4 rounded-3xl border-2   text-xs font-semibold leading-none bg-white   outline-none"
                type="text"
                name="place"
                placeholder="State/Region"
              />
            </div>
            
           
            <div className="flex justify-center items-center">
              
              <button
              type="submit"
                className="inline-block py-2 mb-3 px-6 rounded-l-xl rounded-t-xl bg-blue-900 hover:bg-blue-700 text-gray-50 font-bold leading-loose transition duration-200"
              >
                Book A Demo
              </button>
            </div>
          </form>
        
        </div>
      </div>
       {/* </div>  */}
</div>
        



        <div className="skew skew-bottom mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
          </svg>
        </div>
        <div className="skew skew-bottom ml-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
          </svg>
        </div>
      </section>

      <section>
        <div className="skew skew-top mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
          </svg>
        </div>
        <div className="skew skew-top ml-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
          </svg>
        </div>
        <div className="py-20 bg-blue-100 radius-for-skewed">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap mb-6 lg:mb-20">
              <div className="mb-6 w-full lg:w-1/5">
                <a className="text-3xl font-bold leading-none" href="/">
                  <img
                    style={{width:150,height:100}}
                    src="../../atis-assets/logo/atis/Engageall.svg"
                    alt=""
                    width="auto"
                  />
                </a>
              </div>
              <div className="mb-5 w-full lg:w-1/5">
                <p className="text-gray-400 leading-loose">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  luctus eget justo.
                </p>
              </div>
              <div className="w-full lg:w-3/5 flex flex-wrap -mx-3 justify-end">
                <div className="mb-6 w-full md:w-1/2 lg:w-1/4 lg:mr-6 px-3">
                  <h5 className="mb-4 font-bold">Office</h5>
                  <p className="text-gray-400 leading-loose">
                    359 Hidden Valley Road, NY
                  </p>
                </div>
                <div className="mb-6 w-full md:w-1/2 lg:w-1/4 px-3">
                  <h5 className="mb-4 font-bold">Contacts</h5>
                  <p className="text-gray-400">hello@example.com</p>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-between">
              <p className="text-sm text-gray-400">© 2021. All rights reserved.</p>
              <div className="flex space-x-2 lg:space-x-4">
                <a href="/">
                  <img src="../../atis-assets/social/facebook.svg" alt="" />
                </a>
                <a href="/">
                  <img src="../../atis-assets/social/twitter.svg" alt="" />
                </a>
                <a href="/">
                  <img src="../../atis-assets/social/instagram.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="skew skew-bottom mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
          </svg>
        </div>
        <div className="skew skew-bottom ml-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
          </svg>
        </div>
      </section>
    </>
  )
}

export default Home;
import React from 'react'
import '../../tailwind/TailwindP.css';
import '../../tailwind/tailwind.min.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
const News = () => {
  return (
    <>
    <Navbar></Navbar>
    <div class="w-full">
      <div id="teambgtwo">
        <div class="max-w-2xl mx-auto text-center mb-16">
          <h2
            class="mb-2 text-4xl lg:text-5xl text-white font-bold font-heading"
          >
            News
          </h2>
          <p class="mb-8 text-white">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat
            adipisci, quasi labore necessitatibus cupiditate aut ullam
            voluptatibus at totam fuga similique suscipit fugit natus, tempore
            ab dolores dignissimos magnam nulla.
          </p>
        </div>
      </div>
    </div>

    
    
    <div class="mr-for-radius">
      <svg
        class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
        viewBox="0 0 10 10"
        preserveAspectRatio="none"
      >
        <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
      </svg>
    </div>
    <div class="flex flex-wrap m-10">
      <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
        <div class="p-8 bg-white shadow-xl rounded">
          <h4 class="mb-2 text-2xl font-bold font-heading">News</h4>

          <p class="mt-3 mb-6 text-gray-500 leading-loose">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, dolor similique quam unde debitis doloribus amet
            laudantium facere, tempore minus cumque ipsam temporibus illum?
            Officia placeat exercitationem qui aspernatur soluta!
          </p>
          <a
            class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
            href="news.html"
            >Read More
          </a>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
        <div class="p-8 bg-white shadow-xl rounded">
          <h4 class="mb-2 text-2xl font-bold font-heading">News</h4>

          <p class="mt-3 mb-6 text-gray-500 leading-loose">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, dolor similique quam unde debitis doloribus amet
            laudantium facere, tempore minus cumque ipsam temporibus illum?
            Officia placeat exercitationem qui aspernatur soluta!
          </p>
          <a
            class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
            href="news.html"
            >Read More
          </a>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
        <div class="p-8 bg-white shadow-xl rounded">
          <h4 class="mb-2 text-2xl font-bold font-heading">News</h4>

          <p class="mt-3 mb-6 text-gray-500 leading-loose">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, dolor similique quam unde debitis doloribus amet
            laudantium facere, tempore minus cumque ipsam temporibus illum?
            Officia placeat exercitationem qui aspernatur soluta!
          </p>
          <a
            class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
            href="news.html"
            >Read More
          </a>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
        <div class="p-8 bg-white shadow-xl rounded">
          <h4 class="mb-2 text-2xl font-bold font-heading">News</h4>

          <p class="mt-3 mb-6 text-gray-500 leading-loose">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, dolor similique quam unde debitis doloribus amet
            laudantium facere, tempore minus cumque ipsam temporibus illum?
            Officia placeat exercitationem qui aspernatur soluta!
          </p>
          <a
            class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
            href="news.html"
            >Read More
          </a>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
        <div class="p-8 bg-white shadow-xl rounded">
          <h4 class="mb-2 text-2xl font-bold font-heading">News</h4>

          <p class="mt-3 mb-6 text-gray-500 leading-loose">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, dolor similique quam unde debitis doloribus amet
            laudantium facere, tempore minus cumque ipsam temporibus illum?
            Officia placeat exercitationem qui aspernatur soluta!
          </p>
          <a
            class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
            href="news.html"
            >Read More
          </a>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
        <div class="p-8 bg-white shadow-xl rounded">
          <h4 class="mb-2 text-2xl font-bold font-heading">News</h4>

          <p class="mt-3 mb-6 text-gray-500 leading-loose">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, dolor similique quam unde debitis doloribus amet
            laudantium facere, tempore minus cumque ipsam temporibus illum?
            Officia placeat exercitationem qui aspernatur soluta!
          </p>
          <a
            class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
            href="news.html"
            >Read More
          </a>
        </div>
      </div>

      
    </div>
    <div class="flex justify-center m-10">
      <a
        class="inline-block mb-10 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
        href="/"
        >Load More
      </a>
    </div>
    <Footer></Footer>
    </>
  )
}

export default News
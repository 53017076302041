import React from 'react'
import '../../tailwind/TailwindP.css';
import '../../tailwind/tailwind.min.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
const Signin = () => {
  return (
    <>
    <Navbar></Navbar>
    <div className="">
      <section>
        <div className="flex flex-wrap">
          <div className="pt-6 lg:pt-16 pb-6 w-full lg:w-1/2">
            <div className="max-w-md mx-auto">
              <div
                className="mb-6 lg:mb-20 w-full px-3 flex items-center justify-between"
              >
                <a className="text-3xl font-bold leading-none" href="#">
                  <img
                    className="h-12"
                    src="atis-assets/logo/atis/atis-mono-black.svg"
                    alt=""
                    width="auto"
                  />
                </a>
                <a
                  className="py-2 px-6 text-xs rounded-l-xl rounded-t-xl bg-green-100 hover:bg-green-200 text-blue-1000 font-bold transition duration-200"
                  href="#"
                  >Sign In</a
                >
              </div>
              <div>
                <div className="mb-6 px-3">
                  <span className="text-gray-500">Sign Up</span>
                  <h3 className="text-2xl font-bold">Create an account</h3>
                </div>
                <form action="">
                  <div className="flex flex-wrap">
                    <div className="mb-3 w-full lg:w-1/2 px-2">
                      <input
                        className="w-full p-4 text-xs bg-gray-50 outline-none rounded"
                        type="text"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="mb-3 w-full lg:w-1/2 px-2">
                      <input
                        className="w-full p-4 text-xs bg-gray-50 outline-none rounded"
                        type="text"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                  <div className="mb-3 flex p-4 mx-2 bg-gray-50 rounded">
                    <input
                      className="w-full text-xs bg-gray-50 outline-none"
                      type="email"
                      placeholder="name@email.com"
                    />
                    <svg
                      className="h-6 w-6 ml-4 my-auto text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                      ></path>
                    </svg>
                  </div>
                  <div className="mb-6 flex p-4 mx-2 bg-gray-50 rounded">
                    <input
                      className="w-full text-xs bg-gray-50 outline-none"
                      type="password"
                      placeholder="Enter your password"
                    />
                    <button>
                      <svg
                        className="h-6 w-6 ml-4 my-auto text-gray-300"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        ></path>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="px-3 text-center">
                    <button
                      className="mb-2 w-full py-4 bg-blue-1000 hover:bg-blue-1000 rounded text-sm font-bold text-gray-50 transition duration-200"
                    >
                      Sign Up
                    </button>
                    <span className="text-gray-400 text-xs">
                      <span>Already have an account?</span>
                      <a className="text-blue-1000 hover:underline" href="#"
                        >Sign In</a
                      >
                    </span>
                    <p className="mt-16 text-xs text-gray-400">
                      <a className="underline hover:text-gray-500" href="#"
                        >Policy privacy</a
                      >
                      and
                      <a className="underline hover:text-gray-500" href="#"
                        >Terms of Use</a
                      >
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-full lg:w-1/2">
            <div className="absolute inset-0 bg-gray-800 z-10 opacity-50"></div>
            <img
              className="absolute inset-0 h-full ml-auto object-cover z-0"
              src="https://images.unsplash.com/photo-1565120130276-dfbd9a7a3ad7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80"
              alt=""
            />
            <div
              className="absolute bottom-0 inset-x-0 mx-auto mb-12 max-w-xl text-center"
              style={{zIndex:'10'}}
            >
              <h2 className="mb-2 text-2xl text-white font-bold font-heading">
                So much more than a business analytics tool
              </h2>
              <div className="max-w-lg mx-auto mb-6">
                <p className="text-gray-300 leading-loose">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Pellentesque efficitur nisl sodales egestas lobortis.
                </p>
              </div>
              <a
                className="inline-block py-2 px-6 rounded-t-xl leading-loose rounded-l-xl bg-blue-1000 hover:bg-blue-1000 text-gray-50 font-bold transition duration-200"
                href="#"
                >Get Started</a
              >
              <div className="mt-12 flex justify-center space-x-3">
                <button className="p-1 bg-gray-500 rounded-full"></button>
                <button className="p-1 bg-gray-500 rounded-full"></button>
                <button className="p-1 bg-blue-1000 rounded-full"></button>
                <button className="p-1 bg-gray-500 rounded-full"></button>
              </div>
            </div>
          </div>
          <div className="lg:hidden">
            <div className="relative w-full">
              <img
                className="relative mb-4 object-cover"
                src="https://images.unsplash.com/photo-1565120130276-dfbd9a7a3ad7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80"
                alt=""
              />
              <div
                className="absolute bottom-0 inset-x-0 mb-10 flex justify-center space-x-3"
              >
                <button className="p-1 bg-gray-500 rounded-full"></button>
                <button className="p-1 bg-gray-500 rounded-full"></button>
                <button className="p-1 bg-blue-1000 rounded-full"></button>
                <button className="p-1 bg-gray-500 rounded-full"></button>
              </div>
            </div>
            <div className="py-10 px-3 text-center" style={{zIndex: '10'}}>
              <h2 className="mb-2 text-2xl font-bold">
                So much more than a business analytics tool
              </h2>
              <p className="mb-6 text-gray-500 leading-loose">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque efficitur nisl sodales egestas lobortis.
              </p>
              <a
                className="inline-block py-2 px-6 rounded-t-xl rounded-l-xl bg-blue-1000 hover:bg-blue-1000 text-gray-50 font-bold transition duration-200"
                href="#"
                >Get Started</a
              >
            </div>
          </div>
        </div>
      </section>

      

      <section>
        <div className="skew skew-top mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
          </svg>
        </div>
        <div className="skew skew-top ml-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
          </svg>
        </div>
        <div className="py-20 bg-gray-50 radius-for-skewed">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap mb-6 lg:mb-20">
              <div className="mb-6 w-full lg:w-1/5">
                <a className="text-3xl font-bold leading-none" href="#">
                  <img
                    className="h-12"
                    src="atis-assets/logo/atis/atis-mono-black.svg"
                    alt=""
                    width="auto"
                  />
                </a>
              </div>
              <div className="mb-5 w-full lg:w-1/5">
                <p className="text-gray-400 leading-loose">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  luctus eget justo.
                </p>
              </div>
              <div className="w-full lg:w-3/5 flex flex-wrap -mx-3 justify-end">
                <div className="mb-6 w-full md:w-1/2 lg:w-1/4 lg:mr-6 px-3">
                  <h5 className="mb-4 font-bold">Office</h5>
                  <p className="text-gray-400 leading-loose">
                    359 Hidden Valley Road, NY
                  </p>
                </div>
                <div className="mb-6 w-full md:w-1/2 lg:w-1/4 px-3">
                  <h5 className="mb-4 font-bold">Contacts</h5>
                  <p className="text-gray-400">hello@example.com</p>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-between">
              <p className="text-sm text-gray-400">© 2021. All rights reserved.</p>
              <div className="flex space-x-2 lg:space-x-4">
                <a href="#">
                  <img src="atis-assets/social/facebook.svg" alt="" />
                </a>
                <a href="#">
                  <img src="atis-assets/social/twitter.svg" alt="" />
                </a>
                <a href="#">
                  <img src="atis-assets/social/instagram.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="skew skew-bottom mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
          </svg>
        </div>
        <div className="skew skew-bottom ml-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
          </svg>
        </div>
      </section>
    </div>
<Footer></Footer>
    </>
  )
}

export default Signin
import React from 'react'
import "../../tailwind/tailwind.css";
import Logo from "../../atis-assets/logo/atis/Engageall.svg";
import { useNavigate } from 'react-router-dom';
import Fb from "../../atis-assets/social/facebook.svg";
import Insta from "../../atis-assets/social/instagram.svg";
import Twitter from "../../atis-assets/social/twitter.svg";
const Navbar = () => {
  const navigate =useNavigate();
  return (
    <>
<section className="skewed-bottom-right">
        <nav 
          className="relative px-4 py-2 shadow-xl flex justify-between items-center  bg-blue-50"
        >
          <div className="text-3xl font-bold leading-none" onClick={()=>navigate("/")}>
            <img
              style={{width:150,height:100}}
              src={Logo}
              alt=""
              width="auto"
            />
          </div>
          <div className="lg:hidden">
            <button className="navbar-burger flex items-center text-gray-400 p-3">
              <svg
                className="block h-4 w-4 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Mobile menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </button>
          </div>
          <ul
            className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto  lg:items-center lg:w-auto lg:space-x-6"
          >
            <li className="-ml-40 px-4">
               
              <div
                className="text-sm font-bold text-blue-900 "
                onClick={()=>navigate("/signup")}
                >START</div>
            </li>
            <li id="dropdown" className="relative" x-data="{isOpen:false}">
              <button   className=" block text-sm font-bold outline-none  focus:outline-none  text-blue-900 " href="#">
               COMPANY </button>
                
              
              <div id="dropdown-menu"
                className="right-0 p-2 mt-0 bg-white rounded-md shadow lg:absolute">
                <ul  className="space-y-2 lg:w-48">
                  <li>
                <div onClick={()=>navigate("/team")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Our Team</div></li>
                <li><div onClick={()=>navigate("/careers")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black"> Careers</div></li>

          <li><div onClick={()=>navigate("/careers")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">News</div></li>
          
          
                 
                </ul>
                 </div>
                
            </li>
            <li>
                <svg id="svg" width="12px" height="12px"  viewBox="0 0 64 64" 
                xmlns="http://www.w3.org/2000/svg" 
                stroke-width="8" stroke="#1e3a8a" fill="none">
                  <polyline points="6.53 18.86 33.16 44.12 57.42 18.86"/>
                </svg>
            </li>
           
            <li id="dropdown" className="relative" x-data="{isOpen:false}">
              <button   className=" block text-sm font-bold outline-none  focus:outline-none  text-blue-900 " href="#">
                RESOURCES </button>
                
              
              <div id="dropdown-menu"
                className="right-0 p-2 mt-0 bg-white rounded-md shadow lg:absolute">
                <ul  className="space-y-2 lg:w-48">
                  <li>
                <div onClick={()=>navigate("/resources")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Resources</div></li>
                <li><div onClick={()=>navigate("/marketing")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Marketing kit</div></li>

          <li><div onClick={()=>navigate("/events")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Events</div></li>
          
          <li><div onClick={()=>navigate("/blog")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Blog</div></li>
          <li><div onClick={()=>navigate("/spend")}className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Spend Calculator</div></li>
                 
                </ul>
                 </div>
                
            </li>
           
            <li>
                <svg id="svg" width="12px" height="12px"  viewBox="0 0 64 64" 
                xmlns="http://www.w3.org/2000/svg" 
                stroke-width="8" stroke="#1e3a8a" fill="none">
                  <polyline points="6.53 18.86 33.16 44.12 57.42 18.86"/>
                </svg>
            </li>
              <li id="dropdown" className="relative" x-data="{isOpen:false}">
                <button   className=" block text-sm font-bold outline-none  focus:outline-none  text-blue-900 " href="#">
                  PRODUCTS </button>
                  
                
                <div id="dropdown-menu"
                  className="right-0 p-2 mt-0 bg-white rounded-md shadow lg:absolute">
                  <ul  className="space-y-2 lg:w-48">
                    <li>
                  <div onClick={()=>navigate("/dealers")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Dealers</div></li>
                  <li><div onClick={()=>navigate("/enterprise")} className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black"> Enterprise</div></li>
  
            
                   
                  </ul>
                   </div>
                  
              </li>
            
           
            
           
           
            <li>
                <svg id="svg" width="12px" height="12px"  viewBox="0 0 64 64" 
                xmlns="http://www.w3.org/2000/svg" 
                stroke-width="8" stroke="#1e3a8a" fill="none">
                  <polyline points="6.53 18.86 33.16 44.12 57.42 18.86"/>
                </svg>
            </li>
          
            <li>
              <div className="text-sm font-bold text-blue-900" href="#">TESTIMONIALS</div>
            </li>
            
            
            
            
          </ul>
          <div className="flex justify-between">
            
            <a
          className="hidden lg:inline-block py-2 px-6   text-sm text-blue-900 font-bold "
          href="https://mortgagesaas.netlify.app/authentication/login"
          >LOGIN</a
        >
        
    
    
      <a
        className="hidden lg:inline-block py-2 px-6 bg-blue-900 hover:bg-blue-900 text-sm text-white font-bold rounded-l-xl rounded-t-xl transition duration-200 "
        href="#demoform"
        >BOOK A DEMO</a
      >
        
  
      </div>
        </nav>
        {/* <div className=" pt-12 lg:pt-20 pb-20 bg-white radius-for-skewed">
          <div className="container  mx-auto px-4">
            <div className="flex flex-wrap -mx-4">
              <div
                className="w-full  lg:w-1/2 px-4 mb-12 md:mb-20 lg:mb-0 flex items-center"
              >
                <div className="w-full text-center lg:text-left ">
                  <div className="max-w-md mx-auto lg:mx-0">
                    <h2
                      className="mb-3 text-4xl lg:text-5xl font-bold font-heading"
                    >
                      <span>Pipeline &amp; Audience </span>
                      <span className="text-blue-900">Engagement</span>
                    </h2>
                  </div>
                  <div className="max-w-sm mx-auto lg:mx-0">
                    <p className="mb-6 text-gray-400 leading-loose">
                      Engage every party and exceed expectations and goals by delivering a consistent experience. Build, partnerships and retain customers. 

                    </p>
                    <div>
                      <a
                        className="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
                        href="signup.html"
                        >Get Started</a
                      ><a className="inline-block w-full lg:w-auto py-2 px-6 leading-loose font-semibold bg-white hover:bg-gray-50 rounded-l-xl rounded-t-xl transition duration-200"
                        href="/">How it works</a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="w-full lg:w-1/2 px-4 flex items-center justify-center"
              >
                
                  <div id="bcircle">
                    <div id="scircle">

                    </div>
                  </div> 
                  <div id="bcircletwo" >
                    <div id="scircletwo">

                    </div>
                  </div>
                  <div id="bcirclethree" >
                    <div id="scirclethree">

                    </div>
                  </div>
                  <div className="relative " style={{zIndex:10}}>
                     
                      <img
                      className="h-96 w-128 max-w-lg  object-cover rounded-3xl md:rounded-br-none"
                      src="https://res.cloudinary.com/duuwbcn8o/image/upload/v1670914165/oscar-nord-8l9VxXI28tY-unsplash__1_-removebg-preview_pz7fst.png"
                      alt=""
                    />
                   
                  </div>
                 
                 
               
              </div>
            </div>
          </div>
        </div> */}
        <div className="mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveaspectratio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
          </svg>
        </div>
        <div
          className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50"
        >
          <div
            className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"
          ></div>
          <div
            className="relative flex flex-col py-6 px-6 h-full w-full bg-white border-r overflow-y-auto"
          >
            <div className="flex items-center mb-8">
              <a className="mr-auto text-3xl font-bold leading-none" href="/">
                <img
                 style={{width:150,height:100}}
                  src={Logo}
                  alt=""
                  width="auto"
                />
              </a>
              <button className="navbar-close">
                <svg
                  className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <ul>
                <li className="mb-1">
                  <a
                    className="block p-4 text-sm font-semibold text-blue-900 hover:bg-green-50 hover:text-blue-900 rounded"
                    href="signup.html"
                    >START</a
                  >
                </li>
                <li id="dropdown" className="mb-1">
                  <a
                    className="block p-4 text-sm font-semibold text-blue-900 hover:bg-green-50 hover:text-blue-900 rounded"
                    href="/"
                    >COMPANY <span className="material-icons md-24 blue900 ">
                      keyboard_arrow_down
                      </span></a >
                  <div id="dropdown-menu"
                      className="right-0 p-2 mt-0 bg-white rounded-md shadow lg:absolute">
                      <ul  className="space-y-2 lg:w-48">
                        <li>
                          <a href="team.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Our Team</a></li>
                          <li><a href="careers.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Careers</a></li>
          
                    <li><a href="news.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">News</a></li>
                    
                    
                       
                      </ul>
                       </div>
                </li>
                <li id="dropdown" className="mb-1" x-data="{isOpen:false}">
                  
                  <a
                  className="block p-4 text-sm font-semibold text-blue-900 hover:bg-green-50 hover:text-blue-900 rounded"
                  href="/"
                  >RESOURCES <span className="material-icons md-24 blue900 ">
                    keyboard_arrow_down
                    </span></a
                >
                    
                    <div id="dropdown-menu"
                      className="right-0 p-2 mt-0 bg-white rounded-md shadow lg:absolute">
                      <ul  className="space-y-2 lg:w-48">
                        <li>
                          <a href="resources.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Resources</a></li>
                          <li><a href="marketing.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Marketing kit</a></li>
          
                    <li><a href="events.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Events</a></li>
                    
                    <li><a href="blog.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Blog</a></li>
                    <li><a href="spendcalculator.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Spend Calculator</a></li>
                       
                      </ul>
                       </div>
                      
                  </li>
                 
                 
                </ul>
                
                <li id="dropdown" className="mb-1">
                  <a
                    className="block p-4 text-sm font-semibold text-blue-900 hover:bg-green-50 hover:text-blue-900 rounded"
                    href="/"
                    >PRODUCTS
                    <span className="material-icons md-24 blue900 ">
                      keyboard_arrow_down
                      </span></a
                  > 
                  <div id="dropdown-menu"
                      className="right-0 p-2 mt-0 bg-white rounded-md shadow lg:absolute">
                      <ul  className="space-y-2 lg:w-48">
                        <li>
                          <a href="dealers.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Dealers</a></li>
                          <li><a href="enterprise.html" className="flex p-2 font-medium text-gray-600 rounded-md  hover:bg-gray-100 hover:text-black">Enterprise</a></li>
          
                    
                       
                      </ul>
                       </div>
                </li>
                <li className="mb-1">
                  <a
                    className="block p-4 text-sm font-semibold text-blue-900 hover:bg-green-50 hover:text-blue-900 rounded"
                    href="/"
                    >TESTIMONIALS</a
                  >
                </li>
                <li className="mb-1">
                  <a
                    className="block p-4 text-sm font-semibold text-blue-900 hover:bg-green-50 hover:text-blue-900 rounded"
                    href="#demoform"
                    >BOOK A DEMO</a
                  >
                </li>
            </div>
            <div className="mt-auto">
              <div className="pt-6">
                <a
                  className="block px-4 py-3 mb-3  text-xs text-center font-semibold leading-none bg-gray-50 text-blue-900 hover:bg-gray-100 rounded-l-xl rounded-t-xl"
                  href="/signin"
                  >Sign In</a>
                  <a className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-blue-900 hover:bg-blue-900 rounded-l-xl rounded-t-xl"
                  href="/signup">Sign Up</a>
              </div>
              <p className="my-4 text-xs text-center text-gray-400">
                <span>© 2020 All rights reserved.</span>
              </p>
              <div className="text-center">
                <a className="inline-block px-1" href="/">
                  <img src={Fb} alt="" />
                </a>
                <a className="inline-block px-1" href="/">
                  <img src={Twitter} alt="" />
                </a>
                <a className="inline-block px-1" href="/">
                  <img src={Insta} alt="" />
                </a>
              </div>
              </div>
         </div>
         </div>
   </section>


    </>
  )
}

export default Navbar;
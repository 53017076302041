import '../../tailwind/TailwindP.css';
import '../../tailwind/tailwind.min.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const Careers = () => {
  return (
    <>
    <div className='careers'>
      <Navbar></Navbar>
      <div id="careerbg" class="w-full">
  <div id="careerbgtwo">
    <div class="max-w-2xl mx-auto text-center mb-16">
        <h2 class="mb-2 text-4xl lg:text-5xl font-bold text-white font-heading">Careers</h2>
        <p class="mb-2 text-white lg:text-sm">Come join our dynamic team of individuals dedicated to providing the very best product and customer experience in the automotive technology industry.</p>
        <a
        class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-white text-blue-900 font-semibold rounded-l-xl rounded-t-xl transition duration-200"
        href="/"
        > EXPLORE OPEN ROLES</a>
    </div>
  </div>
</div>

    <div class="container mx-auto  px-4">
        <div class="max-w-2xl mx-auto text-center mb-16">
          
          
          <h2 class="mb-2 text-4xl lg:text-5xl font-bold font-heading">
            Our Perks
          </h2>
          
          <div class="inline-block py-1 px-1 bg-white rounded-lg">
            
           
          </div>
        </div>
        <div class="flex flex-wrap -mx-4">
          <div class=" mt-3 w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
            <div class="p-8 bg-white shadow-xl rounded">
              <h4 class="mb-2 text-2xl font-bold font-heading">Flexible Time Off</h4>
             
              <p class="mt-3 mb-6 text-gray-500 leading-loose">
                We expect people to take their vacation. As long as you update the #vacaypics Slack channel.
              </p>
              
             
            </div>
          </div>
          <div class="w-full mt-3 md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
            <div class="p-8 bg-blue-900 shadow-xl rounded">
              <h4 class="mb-2 text-2xl font-bold text-white">
                Health Benefits
              </h4>
         <h4 class="mb-2 text-2xl font-bold font-heading">
                Remote-First Company
              </h4>
              
              <p class="mt-3 mb-6 text-gray-500 leading-loose">
                Want to WFH? No problem. AutoFi is a remote-first company with a distributed team across the US & Canada.
              </p>
             
            </div>
          </div>
          <div class="flex justify-between md:w-full sm:w-full">
          <div class=" w-full mt-3 md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
            <div class="p-8 mt-3 bg-white shadow-xl rounded">
              <h4 class="mb-2 text-2xl font-bold font-heading">Growth & Development</h4>
             
              <p class="mt-3 mb-6 text-gray-500 leading-loose">
                We foster growth & development by providing AutoFi product training, regular Lunch & Learns, & a $1K educational reimbursement annually.
              </p>
              
             
            </div>
          </div>
          <div class="w-full mt-3 md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
            <div class="p-8 mt-3 bg-white shadow-xl rounded">
              <h4 class="mb-2 text-2xl font-bold font-heading">Equity</h4>
             
              <p class="mt-3 mb-6 text-gray-500 leading-loose">
                We are owners. Building something important takes time. Therefore, we incentivize long-term success by offering all employees an equity package upon hire.
              </p>
              
             
            </div>
          </div>
          

        </div>
        </div>
      </div>
   
    <div class="flex justify-center">
        <a
        class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
        href="/"
        > CHECK OUT OUR VALUES</a>
    </div>
    
    <div class="mr-for-radius">
        <svg
          class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
        </svg>
      </div>

   

    <div class="container my-4 mx-auto px-4">
        <div class="max-w-2xl mx-auto text-center mb-16">
          
          
          <h2 class="mb-2 text-4xl lg:text-5xl font-bold font-heading">
            The Faces Of _____
          </h2>
          
         
        </div>



<div class="w-full  flex flex-row ">
                <div class="lg:mt-20 w-full md:w-1/2 px-4">
                  <div class="mb-8 py-6 pl-6 pr-4 shadow-xl rounded bg-white">
                    <span class="mb-4 inline-block p-3 rounded-lg bg-green-100">
                      
                    </span>
                    <h4 class="mb-2 text-2xl font-bold font-heading">
                      Lorem ipsum dolor sit amet
                    </h4>
                    <p class="text-gray-500 leading-loose">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </div>
                  <div class="py-6 pl-6 pr-4 shadow-xl rounded bg-white">
                    <span class="mb-4 inline-block p-3 rounded-lg bg-green-100">
                      
                    </span>
                    <h4 class="mb-2 text-2xl font-bold font-heading">
                      Lorem ipsum dolor sit amet
                    </h4>
                    <p class="text-gray-500 leading-loose">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
                

                
                  
              </div>
    </div>
    <div class="mr-for-radius">
        <svg
          class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
        </svg>
      </div>



<div class="container mx-auto px-4 my-auto">
    <div class="max-w-2xl mx-auto text-center mb-16">
       
        <h2 class="mb-2 text-4xl lg:text-5xl font-bold font-heading">Testimonials</h2>
        <p class="text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur earum, hic adipisci magni rerum facere dignissimos enim perferendis, dolore qui sapiente perspiciatis distinctio nesciunt quae sint recusandae. Libero, omnis nulla.</p>
        
    </div>
</div>

              <div class="flex flex-wrap m-10">
                
                <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
                    <div class="p-8 bg-white shadow-xl rounded">
                      <h4 class="mb-2 text-2xl font-bold font-heading">Ready to work with us?</h4>

                      <p class="mt-3 mb-6 text-gray-500 leading-loose">
                        The team has grown significantly over the last 12 months and we have several exciting open roles across the organization.
                      </p>
                      <a
                        class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
                        href="careers.html"
                        > EXPLORE OPEN ROLES</a>
                     
                      
                     
                    </div>
                  </div>
                </div>

       
        <div class="skew skew-bottom mr-for-radius">
          <svg
            class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
          </svg>
        </div>
        <div class="skew skew-bottom ml-for-radius">
          <svg
            class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
          </svg>
        </div>        
              <p class="mt-3 mb-6 leading-loose text-gray-50">
                Health, Dental, Vision plans for you and your family. This one should go without saying.
              </p>
      <Footer></Footer>
         
    </div>
    </>
  )
}

export default Careers
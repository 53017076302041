import React from 'react'
import '../../tailwind/TailwindP.css';
import '../../tailwind/tailwind.min.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
const Events = () => {
  return (
    <>
    <Navbar></Navbar>
    
    <div class="w-full">
      <div id="teambgtwo">
        <div class="max-w-2xl mx-auto text-center mb-16">
          <h2
            class="mb-2 text-4xl lg:text-5xl text-white font-bold font-heading"
          >
            Events
          </h2>
          <p class="mb-8 text-white">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat
            adipisci, quasi labore necessitatibus cupiditate aut ullam
            voluptatibus at totam fuga similique suscipit fugit natus, tempore
            ab dolores dignissimos magnam nulla.
          </p>
        </div>
      </div>
    </div>

    <div class="max-w-2xl mx-auto text-center mb-16">
      <h2 class="mb-2 text-4xl lg:text-5xl font-bold font-heading">
        Upcoming Events
      </h2>
    </div>
    <div class="container mx-auto px-4">
      <div class="max-w-2xl mx-auto text-center mb-16">
        <h2 class="mb-2 text-4xl lg:text-5xl font-bold font-heading">
          Past Events
        </h2>
      </div>
    </div>
    <div class="mr-for-radius">
      <svg
        class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
        viewBox="0 0 10 10"
        preserveAspectRatio="none"
      >
        <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
      </svg>
    </div>


    <div class="flex flex-wrap m-10">
      <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
        <div class="p-8 bg-white shadow-xl rounded">
          <h4 class="mb-2 text-2xl font-bold font-heading">Events</h4>

          <p class="mt-3 mb-6 text-gray-500 leading-loose">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, dolor similique quam unde debitis doloribus amet
            laudantium facere, tempore minus cumque ipsam temporibus illum?
            Officia placeat exercitationem qui aspernatur soluta!
          </p>
          <a
            class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
            href="news.html"
            >View More
          </a>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
        <div class="p-8 bg-white shadow-xl rounded">
          <h4 class="mb-2 text-2xl font-bold font-heading">Events</h4>

          <p class="mt-3 mb-6 text-gray-500 leading-loose">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, dolor similique quam unde debitis doloribus amet
            laudantium facere, tempore minus cumque ipsam temporibus illum?
            Officia placeat exercitationem qui aspernatur soluta!
          </p>
          <a
            class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
            href="news.html"
            >View More
          </a>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
        <div class="p-8 bg-white shadow-xl rounded">
          <h4 class="mb-2 text-2xl font-bold font-heading">Events</h4>

          <p class="mt-3 mb-6 text-gray-500 leading-loose">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, dolor similique quam unde debitis doloribus amet
            laudantium facere, tempore minus cumque ipsam temporibus illum?
            Officia placeat exercitationem qui aspernatur soluta!
          </p>
          <a
            class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
            href="news.html"
            >View More
          </a>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
        <div class="p-8 bg-white shadow-xl rounded">
          <h4 class="mb-2 text-2xl font-bold font-heading">Events</h4>

          <p class="mt-3 mb-6 text-gray-500 leading-loose">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, dolor similique quam unde debitis doloribus amet
            laudantium facere, tempore minus cumque ipsam temporibus illum?
            Officia placeat exercitationem qui aspernatur soluta!
          </p>
          <a
            class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
            href="news.html"
            >View More
          </a>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
        <div class="p-8 bg-white shadow-xl rounded">
          <h4 class="mb-2 text-2xl font-bold font-heading">Events</h4>

          <p class="mt-3 mb-6 text-gray-500 leading-loose">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, dolor similique quam unde debitis doloribus amet
            laudantium facere, tempore minus cumque ipsam temporibus illum?
            Officia placeat exercitationem qui aspernatur soluta!
          </p>
          <a
            class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
            href="news.html"
            >View More
          </a>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
        <div class="p-8 bg-white shadow-xl rounded">
          <h4 class="mb-2 text-2xl font-bold font-heading">Events</h4>

          <p class="mt-3 mb-6 text-gray-500 leading-loose">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, dolor similique quam unde debitis doloribus amet
            laudantium facere, tempore minus cumque ipsam temporibus illum?
            Officia placeat exercitationem qui aspernatur soluta!
          </p>
          <a
            class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
            href="news.html"
            >View More
          </a>
        </div>
      </div>

      
    </div>
    <div class="flex justify-center m-10">
      <a
        class="inline-block mb-10 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-blue-900 hover:bg-blue-900 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200"
        href="/"
        >Load More
      </a>
    </div>

    <div class="py-20 bg-gray-50 radius-for-skewed">
    <div class="container mx-auto px-4 my-auto">
      <div id="demoform" class="max-w-2xl mx-auto text-center mb-16">
  
          <h2 class="mb-2 text-4xl lg:text-5xl font-bold font-heading">BOOK A DEMO</h2>
          
      </div>
  
     
        <div class="w-full shadow-xl lg:w-full md:w-1/2 sm:w-1/2">
          <div class="max-w-md lg:mx-auto ">
            <form action="#demoform" method="POST" class="my-6">
            
              <div class="inline-block m-4  ">
                <input
                  class="lg:w-full rounded-3xl border-2 text-black p-4 text-xs font-semibold leading-none bg-white  outline-none"
                  type="text"
                  name="firstname"
                  placeholder="First Name"
                />
              </div>
              <div class=" inline-block m-4  ">
                <input
                  class="lg:w-full p-4 rounded-3xl border-2  text-xs font-semibold leading-none bg-white  outline-none"
                  type="text"
                  name="lastname"
                  placeholder="Last Name"
                />
              </div>
              <div class="inline-block m-4 ">
                <input
                  class="lg:w-full p-4  border-2  only:text-xs font-semibold leading-none bg-white rounded-3xl outline-none"
                  type="email"
                  name="email"
                  placeholder="name@example.com"
                />
              </div>
              <div class="inline-block m-4 ">
                <input
                  class="w-full  rounded-3xl border-2  p-4 text-xs font-semibold leading-none bg-white  outline-none"
                  type="text"
                  name="number"
                  placeholder="Phone Number"
                />
              </div>
              <div class="inline-block m-4 ">
                <input
                  class="w-full p-4   text-xs font-semibold leading-none bg-white rounded-3xl border-2 outline-none"
                  type="text"
                  name="companyname"
                  placeholder="Company Name"
                />
              </div>
              <div class="inline-block m-4 ">
                <input
                  class="w-full p-4 rounded-3xl border-2   text-xs font-semibold leading-none bg-white   outline-none"
                  type="text"
                  name="place"
                  placeholder="State/Region"
                />
              </div>
              
             
              <div class="flex justify-center items-center">
                
                <button
                type="submit"
                  class="inline-block py-2 mb-3 px-6 rounded-l-xl rounded-t-xl bg-blue-900 hover:bg-blue-700 text-gray-50 font-bold leading-loose transition duration-200"
                >
                  Book A Demo
                </button>
              </div>
            </form>
          </div>
        </div>
        </div>
  </div>
  
  <div class="skew skew-bottom mr-for-radius">
    <svg
      class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
      viewBox="0 0 10 10"
      preserveAspectRatio="none"
    >
      <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
    </svg>
  </div>
  <div class="skew skew-bottom ml-for-radius">
    <svg
      class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
      viewBox="0 0 10 10"
      preserveAspectRatio="none"
    >
      <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
    </svg>
  </div>
    <Footer></Footer>
    </>
  )
}

export default Events
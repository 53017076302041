import React from 'react'
import '../../tailwind/TailwindP.css';
import '../../tailwind/tailwind.min.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const Dealers = () => {
  return (
    <><Navbar>
      </Navbar>
      
      <Footer></Footer>
      </>
  )
}

export default Dealers
import React from 'react'
import '../../tailwind/TailwindP.css';
import '../../tailwind/tailwind.min.css';
import Navbar from '../Navbar/Navbar';
const Blog = () => {
  return (
    <>
    <div className='Blog'>
    <Navbar></Navbar>


    </div>
    </>
  )
}

export default Blog

import '../../tailwind/TailwindP.css';
import '../../tailwind/tailwind.min.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
const Resources = () => {
  return (
    <>
    <Navbar></Navbar>
    <div class="w-full">
      <div id="teambgtwo">
        <div class="max-w-2xl mx-auto text-center mb-16">
          <h2
            class="mb-2 text-4xl lg:text-5xl text-white font-bold font-heading"
          >
            Resources
          </h2>
          <p class="mb-8 text-white">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat
            adipisci, quasi labore necessitatibus cupiditate aut ullam
            voluptatibus at totam fuga similique suscipit fugit natus, tempore
            ab dolores dignissimos magnam nulla.
          </p>
        </div>
      </div>
    </div>

    
    
    <div class="mr-for-radius">
      <svg
        class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
        viewBox="0 0 10 10"
        preserveAspectRatio="none"
      >
        <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
      </svg>
    </div>
    <Footer></Footer>

    </>
  )
}

export default Resources

import '../../tailwind/TailwindP.css';
import '../../tailwind/tailwind.min.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
const Marketing = () => {
  return (
    <>
    <Navbar></Navbar>
    <div class="w-full">
  <div id="teambgtwo">
    <div class="max-w-2xl mx-auto text-center mb-16">
      <h2
        class="mb-2 text-4xl lg:text-5xl text-white font-bold font-heading"
      >
        Marketing Kit
      </h2>
      <p class="mb-8 text-white">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat
        adipisci, quasi labore necessitatibus cupiditate aut ullam
        voluptatibus at totam fuga similique suscipit fugit natus, tempore
        ab dolores dignissimos magnam nulla.
      </p>
    </div>
  </div>
</div>
   

  

   



<div class="w-full  flex flex-row ">
                <div class="lg:mt-20 m-10 w-full md:w-1/2 px-4">
                  <div class="mb-8 py-6 pl-6 pr-4 shadow-xl rounded bg-white">
                    <span class="mb-4 inline-block p-3 rounded-lg bg-green-100">
                      
                    </span>
                    <h4 class="mb-2 text-2xl font-bold font-heading">
                      Download 
                    </h4>
                   
                  </div>
                  <div class="py-6 pl-6 pr-4 shadow-xl rounded bg-white">
                    <span class="mb-4 inline-block p-3 rounded-lg bg-green-100">
                      
                    </span>
                    <h4 class="mb-2 text-2xl font-bold font-heading">
                        Download
                    </h4>
                    
                  </div>
                </div>
                <div class="w-full md:w-1/2 lg:mt-20 px-4">
                  <div class="mb-8 py-6 pl-6 pr-4 shadow-xl rounded-lg bg-white">
                    <span class="mb-4 inline-block p-3 rounded bg-green-100">
                     
                    </span>
                    <h4 class="mb-2 text-2xl font-bold font-heading">
                        Download
                    </h4>
                    
                  </div>
                  <div class="py-6 pl-6 pr-4 shadow-xl rounded-lg bg-white">
                    <span class="mb-4 inline-block p-3 rounded bg-green-100">
                      
                    </span>
                    <h4 class="mb-2 text-2xl font-bold font-heading">
                        Download
                    </h4>
                    
                  </div>
                </div>

                <div class="w-full md:w-1/2 lg:mt-20 px-4">
                    <div class="mb-8 py-6 pl-6 pr-4 shadow-xl rounded-lg bg-white">
                      <span class="mb-4 inline-block p-3 rounded bg-green-100">
                       
                      </span>
                      <h4 class="mb-2 text-2xl font-bold font-heading">
                        Download
                      </h4>
                      
                    </div>
                    <div class="py-6 pl-6 pr-4 shadow-xl rounded-lg bg-white">
                      <span class="mb-4 inline-block p-3 rounded bg-green-100">
                        
                      </span>
                      <h4 class="mb-2 text-2xl font-bold font-heading">
                        Download
                      </h4>
                      
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 lg:mt-20 px-4">
                    <div class="mb-8 py-6 pl-6 pr-4 shadow-xl rounded-lg bg-white">
                      <span class="mb-4 inline-block p-3 rounded bg-green-100">
                       
                      </span>
                      <h4 class="mb-2 text-2xl font-bold font-heading">
                        Download
                      </h4>
                      
                    </div>
                    <div class="py-6 pl-6 pr-4 shadow-xl rounded-lg bg-white">
                      <span class="mb-4 inline-block p-3 rounded bg-green-100">
                        
                      </span>
                      <h4 class="mb-2 text-2xl font-bold font-heading">
                        Download
                      </h4>
                     
                    </div>
                  </div>
              </div>
   
    <div class="mr-for-radius">
        <svg
          class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
        </svg>
      </div>

             

<div class="container mx-auto px-4 my-auto">
    <div id="demoform" class="max-w-2xl mx-auto text-center mb-16">

        <h2 class="mb-2 text-4xl lg:text-5xl font-bold font-heading">BOOK A DEMO</h2>
        
    </div>

    <div class="py-20 bg-gray-50 radius-for-skewed">
      <div class="w-full lg:w-full md:w-1/2 sm:w-1/2">
        <div class="max-w-md lg:mx-auto ">
          <form action="#demoform" method="POST" class="my-6">
            
            <div class="inline-block m-4  ">
              <input
                class="lg:w-full rounded-3xl border-2 text-black p-4 text-xs font-semibold leading-none bg-white  outline-none"
                type="text"
                name="firstname"
                placeholder="First Name"
              />
            </div>
            <div class=" inline-block m-4  ">
              <input
                class="lg:w-full p-4 rounded-3xl border-2  text-xs font-semibold leading-none bg-white  outline-none"
                type="text"
                name="lastname"
                placeholder="Last Name"
              />
            </div>
            <div class="inline-block m-4 ">
              <input
                class="lg:w-full p-4  border-2  only:text-xs font-semibold leading-none bg-white rounded-3xl outline-none"
                type="email"
                name="email"
                placeholder="name@example.com"
              />
            </div>
            <div class="inline-block m-4 ">
              <input
                class="w-full  rounded-3xl border-2  p-4 text-xs font-semibold leading-none bg-white  outline-none"
                type="text"
                name="number"
                placeholder="Phone Number"
              />
            </div>
            <div class="inline-block m-4 ">
              <input
                class="w-full p-4   text-xs font-semibold leading-none bg-white rounded-3xl border-2 outline-none"
                type="text"
                name="companyname"
                placeholder="Company Name"
              />
            </div>
            <div class="inline-block m-4 ">
              <input
                class="w-full p-4 rounded-3xl border-2   text-xs font-semibold leading-none bg-white   outline-none"
                type="text"
                name="place"
                placeholder="State/Region"
              />
            </div>
            
           
            <div class="flex justify-center items-center">
              
              <button
              type="submit"
                class="inline-block py-2 mb-3 px-6 rounded-l-xl rounded-t-xl bg-blue-900 hover:bg-blue-700 text-gray-50 font-bold leading-loose transition duration-200"
              >
                Book A Demo
              </button>
            </div>
          </form>
        </div>
      </div>
      </div>
</div>
     
        <div class="skew skew-bottom mr-for-radius">
          <svg
            class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
          </svg>
        </div>
        <div class="skew skew-bottom ml-for-radius">
          <svg
            class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
          </svg>
          </div>

          <Footer></Footer>
      
    </>
  )
}

export default Marketing